<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8">
      <h2 class="font-weight-bold my-2 mr-5">
        {{ name.tr }}
      </h2>
      <div class="d-flex flex-row align-items-center">
        <router-link
        :to="{ name: 'categories'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2">
          <span class="d-none d-md-inline">Kategoriler</span>
        </a>
      </router-link>
      <router-link
        :to="{ name: 'create-category'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="svg-icon">
            <inline-svg src="media/svg/icons/Code/Plus.svg" />
          </span>
          <span class="d-none d-md-inline">Kategori Ekle</span>
        </a>
      </router-link>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <div class="d-flex justify-content-between flex-column pt-4 h-100">
              <!--begin::Container-->
              <div class="pb-5">
                <!--begin::Header-->
                <div class="d-flex flex-column flex-center pb-6">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-100 bg-gray-100 p-2">
                    <img :src="icon" class="h-75 align-self-end" :alt="name.tr">
                    <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" v-b-modal.modal-change-icon id="btn_change_avatar" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                      <i class="fa fa-pen icon-sm text-muted"></i>
                    </label>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Username-->
                  <span class="card-title font-weight-bolder text-dark-75 font-size-h4 m-0 pt-7">{{ name.tr }}</span>
                  <span class="font-weight-bold text-muted font-size-h6">{{ name.en }}</span>
                  <!--end::Username-->
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="pt-1">
                  
                  <!--begin::Nav-->
                  <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
                    <div class="navi-item mb-2">
                      <a
                        class="navi-link py-4 active"
                        @click="setActiveTab"
                        href="javascript:;"
                        data-tab="0"
                        data-toggle="tab"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="navi-icon mr-2">
                          <span class="svg-icon">
                            <inline-svg src="media/svg/icons/Design/Layers.svg" />
                          </span>
                        </span>
                        <span class="navi-text font-size-h6">Genel Bakış</span>
                      </a>
                    </div>
                    <div class="navi-item mb-2">
                      <a
                        class="navi-link py-4"
                        @click="setActiveTab"
                        href="javascript:;"
                        data-tab="1"
                        data-toggle="tab"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="navi-icon mr-2">
                          <span class="svg-icon">
                            <inline-svg
                              src="media/svg/icons/Communication/Flag.svg"
                            />
                          </span>
                        </span>
                        <span class="navi-text font-size-h6">Tur ve Aktiviteler</span>
                      </a>
                    </div>
                    <div class="navi-item mb-2">
                      <a
                        class="navi-link py-4"
                        @click="setActiveTab"
                        href="javascript:;"
                        data-tab="2"
                        data-toggle="tab"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="navi-icon mr-2">
                          <span class="svg-icon">
                            <inline-svg src="media/svg/icons/Code/Git4.svg" />
                          </span>
                        </span>
                        <span class="navi-text font-size-h6">Alt Kategoriler</span>
                      </a>
                    </div>
                    <div class="navi-item mb-2">
                      <a
                        class="navi-link py-4"
                        @click="setActiveTab"
                        href="javascript:;"
                        data-tab="3"
                        data-toggle="tab"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="navi-icon mr-2">
                          <span class="svg-icon">
                            <inline-svg src="media/svg/icons/Communication/Write.svg" />
                          </span>
                        </span>
                        <span class="navi-text font-size-h6">Düzenle</span>
                      </a>
                    </div>
                    <div class="navi-item mb-2">
                      <a
                        class="navi-link py-4"
                        @click="setActiveTab"
                        href="javascript:;"
                        data-tab="4"
                        data-toggle="tab"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="navi-icon mr-2">
                          <span class="svg-icon">
                            <inline-svg src="media/svg/icons/General/Settings-2.svg" />
                          </span>
                        </span>
                        <span class="navi-text font-size-h6">Ayarlar</span>
                      </a>
                    </div>
                  </div>
                  <!--end::Nav-->
                </div>
                <!--end::Body-->
              </div>
              <!--eng::Container-->
            </div>
          </div>
        </div>
      </div>

      <!--begin::Content-->
      <div class="flex-row-fluid ml-lg-8">
        <b-tabs class="hide-tabs" v-model="tabIndex" lazy>
          <b-tab active>
            <CategoryOverview :category-id="categoryId"></CategoryOverview>
          </b-tab>

          <b-tab>
            <CategoryExperiences :category-id="categoryId"></CategoryExperiences>
          </b-tab>

          <b-tab>
            <SubCategory :category-id="categoryId"></SubCategory>
          </b-tab>

          <b-tab>
            <CategoryEdit :get-brief-details="getBriefDetails" :category-id="categoryId"></CategoryEdit>
          </b-tab>

          <b-tab>
            <CategorySettings :category-id="categoryId"></CategorySettings>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal id="modal-change-icon" ref="modal-change-icon" centered title="İkonu Güncelle" ok-title="Değişiklikleri Kaydet" cancel-title="Vazgeç" @ok="handleOk">
      <div class="d-flex align-items-center justify-content-between mb-8">
        <div class="symbol symbol-70 flex-shrink-0 mr-4">
          <div class="symbol-label">
            <span class="text-dark-65 font-weight-bolder">20 KB</span>
          </div>
        </div>
        <div class="symbol symbol-70 flex-shrink-0 mr-4">
          <div class="symbol-label">
            <span class="text-dark-65 font-weight-bolder">SVG</span>
          </div>
        </div>
        <div class="symbol symbol-70 flex-shrink-0 mr-4">
          <div class="symbol-label">
            <span class="text-dark-65 font-weight-bolder">1:1</span>
          </div>
        </div>
        <div class="symbol symbol-70 flex-shrink-0 mr-4">
          <div class="symbol-label">
            <div class="d-flex flex-column align-items-center">
              <span class="text-dark-65 font-weight-light">Width</span>
              <span class="text-dark-65 font-weight-bolder">250 px</span>
            </div>
          </div>
        </div>
        <div class="symbol symbol-70 flex-shrink-0 mr-4">
          <div class="symbol-label">
            <div class="d-flex flex-column align-items-center">
              <span class="text-dark-65 font-weight-light">Height</span>
              <span class="text-dark-65 font-weight-bolder">250 px</span>
            </div>
          </div>
        </div>
      </div>
      <file-pond
        name="icon"
        allow-multiple="false" 
        accepted-file-types="image/*"
        max-files="1" 
        maxFileSize="20KB"
        :file-rename-function="renameFile" 
        v-on:removefile="removeFile"
        :server="{ process }"
        label-idle='<div style="width:100%;height:100%;">
          <h4 style="font-size: 16px">
            Drag &amp; Drop your files or <span class="filepond--label-action" tabindex="0">Browse</span><br>
          </h4>
        </div>'
      />
      <input type="hidden" ref="newIcon" name="newIcon" v-model="newIcon">
      <span class="form-text text-danger" v-if="$v.newIcon.$error">Lütfen bir ikon yükleyin.</span>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import JwtService from '@/core/services/jwt.service';
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";

import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm.js';
import FilePondPluginFileRename from 'filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm.js';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginFileRename);

import CategoryOverview from "@/view/pages/category/components/CategoryOverview";
import CategoryExperiences from "@/view/pages/category/components/CategoryExperiences";
import SubCategory from "@/view/pages/category/components/sub_category/SubCategory";
import CategorySettings from "@/view/pages/category/components/CategorySettings";
import CategoryEdit from "@/view/pages/category/components/CategoryEdit";

export default {
  mixins: [validationMixin],
  name: "CategoryProfile",
  components: {
    FilePond,
    CategoryOverview,
    CategoryExperiences,
    SubCategory,
    CategorySettings,
    CategoryEdit
  },
  data() {
    return {
      tabIndex: -1,
      categoryId: this.$route.params.id,
      name: {
        tr: '',
        en: ''
      },
      icon: '',
      newIcon: ''
    }
  },
  validations: {
    newIcon: {
      required
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Category" }]);

    this.getBriefDetails();
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },

    getBriefDetails() {
      ApiService.get(`category/getBriefDetails.req.php?id=${this.categoryId}`)
      .then(({ data }) => {
        this.name = data.name;
        this.icon = data.icon;
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },

    process (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append('image', file, file.name);

      const request = new XMLHttpRequest();
      request.open("POST", "https://api.zenofontours.com/admin/v1/category/uploadIcon.req.php");
      request.setRequestHeader('Authorization', JwtService.getToken());

      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      const self = this;
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          self.newIcon = file.name;
          load();
        } else {
          // Can call the error method if something is wrong, should exit after
          self.newIcon = '';
          error();
        }
      };

      request.send(formData);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        }
      }; 
    },

    renameFile(file) {
      return Date.now() + '_' + file.name;
    },

    removeFile() {
      this.newIcon = '';
    },

    setIcon() {
      this.$v.newIcon.$touch();
      if (this.$v.newIcon.$invalid) { 
        return;
      }

      ApiService.post('category/setIcon.req.php', { id: this.categoryId, icon: this.newIcon })
      .then(() => {
        this.getBriefDetails();
        this.$bvModal.hide('modal-change-icon');
      })
      .catch((({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      })) 
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.setIcon();
    }
  }
}
</script>

<style>
#btn_change_avatar {
  position: absolute;
  top: -8px;
  right: -8px;
}

.filepond--panel-root {
  background-color: transparent !important;
  border: 3px dashed #EBEDF3;
}
.filepond--wrapper.invalid .filepond--panel-root {
  border-color: #F64E60 !important;
}
.filepond--drop-label {
  background-color: transparent !important;
  height: 100% !important;
}

</style>