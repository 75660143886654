<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bold text-dark">
          Düzenle
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Kategori bilgilerini düzenle</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          ref="kt_save_changes"
          @click="setDetails"
        >
          Değişiklikleri Kaydet
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" @submit.prevent="setDetails">
      <!--begin::Body-->
      <div class="card-body">
        
        <div class="d-flex justify-content-center">
          <b-alert class="col-xl-6 mb-8" :show="alert.dismissCountDown" :variant="alert.variant" dismissible fade @dismissed="dismissAlert()" @dismiss-count-down="countDownChanged">{{ alert.message }}</b-alert>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Kategori Adı</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Türkçe</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="nameTR"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.form.categoryName.tr.$model"
            />
            <span class="form-text text-danger" v-if="$v.form.categoryName.tr.$error">Bu alan boş geçilemez.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >İngilizce</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="nameEN"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.form.categoryName.en.$model"
            />
            <span class="form-text text-danger" v-if="$v.form.categoryName.en.$error">Bu alan boş geçilemez.</span>
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Kategori Açıklaması</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Türkçe</label
          >
          <div class="col-lg-9 col-xl-6">
            <textarea ref="descTR" class="form-control form-control-lg form-control-solid" rows="2" v-model.trim="form.categoryDesc.tr"></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >İngilizce</label
          >
          <div class="col-lg-9 col-xl-6">
            <textarea ref="descEN" class="form-control form-control-lg form-control-solid" rows="2" v-model.trim="form.categoryDesc.en"></textarea>
          </div>
        </div>
        
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form--> 

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';

import { validationMixin } from 'vuelidate';
import { required} from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  name: 'CategoryEdit',
  props: {
    getBriefDetails: Function,
    categoryId: String
  },
  data() {
    return {
      form: {
        categoryName: {
          tr: '',
          en: ''
        },
        categoryDesc: {
          tr: '',
          en: ''
        }
      },
      alert: {
        dismissCountDown: 0,
        variant: 'secondary',
        message: ''

      }
    }
  },
  validations: {
    form: {
      categoryName: {
        tr: {
          required
        },
        en: {
          required
        }
      },
    }
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
       ApiService.get(`category/getDetails.req.php?id=${this.categoryId}`)
      .then(({ data }) => {
        this.form.categoryName = data.details.title;
        this.form.categoryDesc = data.details.summary;
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
          heightAuto: false
        });
      });
    },

    setDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        
        const submitButton = this.$refs['kt_save_changes'];
        submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

        const data = {
          id: this.categoryId,
          title: JSON.stringify({
            tr: this.$refs.nameTR.value,
            en: this.$refs.nameEN.value
          }),
          summary: JSON.stringify({
            tr: this.$refs.descTR.value,
            en: this.$refs.descEN.value
          })
        };
        ApiService.post('category/setDetails.req.php', data)
        .then(() => {
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
          this.getDetails();
          this.getBriefDetails();
          this.showAlert('success', 'Değişiklikler kaydedildi!');
        })
        .catch(({ response }) => {
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
          this.showAlert('danger', response.data.errorMessage);
        });
      }
      
    },
    
    // Show Alert
    showAlert(variant, message) {
      this.alert.variant = variant;
      this.alert.message = message;
      this.alert.dismissCountDown = 5;
    },

    // Dismiss Alert
    dismissAlert() {
      this.alert.dismissCountDown = 0;
      this.alert.message = '';
      this.alert.variant = 'secondary';
    },

    // Alert count down changed
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown;
    },
  }
}
</script>

<style>

</style>