<template>
  <div>
    <div class="card card-custom bg-light card-stretch">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">Alt Kategoriler
            <span class="d-block text-muted pt-2 font-size-sm">Toplam {{ subCategories.length }} adet alt kategori bulunmakta</span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <b-table
          class="table table-head-custom table-head-bg table-borderless table-vertical-center"
          thead-class="d-none"
          show-empty
          empty-html='<div class="alert alert-custom alert-secondary" role="alert"><div class="alert-text">Listelenecek herhangi bir alt kategori bulunamadı</div></div>'
          stacked="lg"
          :items="subCategories"
          :fields="subCategoriesDataTableFields"
          :current-page="currentPage"
          :per-page="perPage"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Yükleniyor...</strong>
            </div>
          </template>
          <template #cell(subCategory)="data">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{ data.item.title.tr }}</span>
                <span class="text-muted font-weight-bold d-block">{{ data.item.title.en }}</span>
              </div>
            </div>
          </template>
          <template #cell(actions)="data">
            <button class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3" @click="showModal(data.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Communication/Write.svg"/>
              </span>
            </button>
            <button class="btn btn-icon btn-light btn-hover-primary btn-sm" @click="remove(data.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/General/Trash.svg" />
              </span>
            </button>
          </template>
        </b-table>
        <b-col sm="7" md="6" class="my-1 pl-0" v-if="totalRows > perPage">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            class="my-0"
            size="md"
          >
          </b-pagination>
        </b-col>
      </div>
    </div>

    <b-modal v-if="false" id="modal-edit-sub-category" ref="modal-edit-sub-category" centered title="Alt Kategori | Düzenle" ok-title="Değişiklikleri Kaydet" cancel-title="Vazgeç" @ok="handleOk">
      <form class="form" ref="editSubCategoryFrom" @submit.stop.prevent="edit">
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Türkçe Adı</label>
              <input type="text" class="form-control form-control-lg form-control-solid" ref="editSubCategoryNameTR" placeholder="Kategori adı girin" v-model.trim="$v.editForm.subCategoryNameTR.$model">
              <span class="form-text text-danger" v-if="$v.editForm.subCategoryNameTR.$error">Bu alan boş geçilemez.</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>İngilizce Adı</label>
              <input type="text" class="form-control form-control-lg form-control-solid" ref="editSubCategoryNameEN" placeholder="Kategori adı girin" v-model.trim="$v.editForm.subCategoryNameEN.$model">
              <span class="form-text text-danger" v-if="$v.editForm.subCategoryNameEN.$error">Bu alan boş geçilemez.</span>
            </div>
          </div>
        </div>
      </form>  
    </b-modal>

    <b-modal id="modal-edit-sub-category" ref="modal-edit-sub-category" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <div class="d-flex align-items-center">
              <h5 class="ml-2 mb-0">
                {{ modal.steps[modal.activeStep - 1].title }}
              </h5>
            </div>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <div v-if="modal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Türkçe adı</label>
            <input type="text" class="form-control form-control-lg" v-model="modal.subCategoryNameTR">
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">İngilizce adı</label>
            <input type="text" class="form-control form-control-lg" v-model="modal.subCategoryNameEN">
          </div>
        </div>
      </div>
      
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
            v-if="modal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.subCategoryNameTR.length === 0 || modal.subCategoryNameEN.length === 0"
            @click="ok" 
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "SubCategoryListings",
  props: ["categoryId"],
  data() {
    return {
      // sub category editing form
      editForm: {
        subCategoryId: null,
        subCategoryNameTR: '',
        subCategoryNameEN: '',
      },
      modal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Alt kategori başlığı'
          },
        ],
        subCategoryId: null,
        subCategoryNameTR: '',
        subCategoryNameEN: '',
      },

      // Sub category list elements
      isBusy: true,
      subCategories: [],
      subCategoriesDataTableFields: [
        { key: 'subCategory', label: 'Alt Kategori', sortable: false, class: 'pl-0' },
        { key: 'actions', label: '', sortable: false, class: 'text-right' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5
    }
  },
  validations: {
    editForm: {
      subCategoryNameTR: {
        required
      },
      subCategoryNameEN: {
        required
      }
    }
  },
  created() {
    // set componenent name
    this.$root.$refs.subCategoryListings = this;
  },
  mounted() {
    this.getSubCategories();
  },
  methods: {
    updateColorValue(val) {
      this.modal.color = val.hex;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getSubCategories() {
      this.isBusy = true;
      ApiService.get(`category/getSubCategories.req.php?id=${this.categoryId}`)
      .then(({ data }) => {
        this.subCategories = data.subCategories;
        this.totalRows = this.subCategories.length;
        this.isBusy = false;
      })
      .catch(({ response }) => {
        this.isBusy = false;
        this.subCategories = [];
        this.totalRows = 0;
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },

    // remove sub category
    remove(id) {
      const self = this;
      Swal.fire({
        title: "Alt kategoriyi silmek istediğinize emin misiniz?",
        showCancelButton: true,
        cancelButtonClass: "btn btn-secondary",
        cancelButtonText: "Vazgeç",
        confirmButtonClass: "btn btn-primary",
        confirmButtonText: "Evet, sil!",
        heightAuto: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          ApiService.post('category/removeSubCategory.req.php', { subCategoryId: id })
          .then(() => {
            self.getSubCategories();
          })
          .catch((({ response }) => {
            Swal.fire({
              title: response.data.error,
              text: response.data.errorMessage,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
          }))
        }
      })

      
    },

    showModal(subCategoryId) {
      let item = this.subCategories.filter((subCategory) => {
        return subCategory.id === subCategoryId;
      });
      this.modal.subCategoryId = subCategoryId;
      this.modal.subCategoryNameTR = item[0].title.tr;
      this.modal.subCategoryNameEN = item[0].title.en;
      this.$refs['modal-edit-sub-category'].show();
    },

    handleModalOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.edit();
    },

    handleModalCancel() {
      this.modal.activeStep = 1;
      this.modal.subCategoryId = null;
      this.modal.subCategoryNameTR = '';
      this.modal.subCategoryNameEN = '';
    },

    edit() {
      const data = {
        subCategoryId: this.modal.subCategoryId,
        title: JSON.stringify({
          tr: this.modal.subCategoryNameTR,
          en: this.modal.subCategoryNameEN,
        })
      }
      
      ApiService.post('category/updateSubCategory.req.php', data)
      .then(() => {
        // loading sub categories
        this.getSubCategories();
        this.$bvModal.hide('modal-edit-sub-category');
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      })
    }
  }
}
</script>