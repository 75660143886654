<template>
  <div class="card card-custom bg-light-warning">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Yeni Alt Kategori</h3>
      </div>
    </div>
    <div class="card-body">
      <form class="form" ref="createSubCategoryFrom" @submit.prevent="create">
        <div class="form-group">
          <label>Türkçe Adı</label>
          <input type="text" class="form-control" ref="subCategoryNameTR" placeholder="Kategori adı girin" v-model.trim="$v.form.subCategoryNameTR.$model">
          <span class="form-text text-danger" v-if="$v.form.subCategoryNameTR.$error">Bu alan boş geçilemez.</span>
        </div>
        <div class="form-group">
          <label>İngilizce Adı</label>
          <input type="text" class="form-control" ref="subCategoryNameEN" placeholder="Kategori adı girin" v-model.trim="$v.form.subCategoryNameEN.$model">
          <span class="form-text text-danger" v-if="$v.form.subCategoryNameEN.$error">Bu alan boş geçilemez.</span>
        </div>
        <div class="form-group">
          <button type="submit" ref="btn_create_sub_category" class="btn btn-primary btn-block font-weight-bold">Oluştur</button>
        </div>
      </form>          
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "CreateSubCategory",
  props: ["categoryId"],
  data() {
    return {
      // sub category creation form
      form: {
        subCategoryNameTR: '',
        subCategoryNameEN: ''
      }
    }
  },
  validations: {
    form: {
      subCategoryNameTR: {
        required
      },
      subCategoryNameEN: {
        required
      }
    }
  },
  methods: {
    // create new sub category
    create() {
      // checking form validation
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // if form valid 
        const submitButton = this.$refs["btn_create_sub_category"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        const data = {
          categoryId: this.categoryId,
          title: JSON.stringify({
            tr: this.$refs.subCategoryNameTR.value,
            en: this.$refs.subCategoryNameEN.value,
          })
        }
        
        ApiService.post('category/createSubCategory.req.php', data)
        .then(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          // Restting form
          this.form.subCategoryNameTR = '';
          this.form.subCategoryNameEN = '';
          this.$v.$reset();

          // loading sub categories
          this.$root.$refs.subCategoryListings.getSubCategories();
        })
        .catch(({ response }) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire({
            title: response.data.error,
            text: response.data.errorMessage,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        })
      }
    }
  }
}
</script>