<template>
  <div class="card card-custom bg-light card-stretch gutter-b" v-if="error.status || dataToShow.length > 0">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">En çok satanlar</h3>
      <!--begin::Toolbar-->
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'pax' }"
              href="javascript:;"
              @click="show = 'pax'"
            >
              <span class="nav-text font-size-sm">Pax</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'quantity' }"
              href="javascript:;"
              @click="show = 'quantity'"
            >
              <span class="nav-text font-size-sm">Adet</span>
            </a>
          </li>
        </ul>
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <!--begin::Item-->
      <div class="d-flex align-items-center mb-10" v-for="item in dataToShow" :key="item.id">
        <!--begin::Symbol-->
        <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
          <div class="symbol-label" :style="{ backgroundImage: `url(${item.image})` }"></div>
        </div>
        <!--end::Symbol-->
        <!--begin::Title-->
        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
          <span class="text-dark-75 font-weight-bold font-size-lg">{{ item.name }}</span>
        </div>
        <!--end::Title-->
        <!--begin::Info-->
        <div class="d-flex align-items-center py-lg-0 py-2">
          <div class="d-flex flex-column text-center">
            <span class="font-weight-bolder text-dark-65 font-size-h4">{{ item.amount }}</span>
            <span class="text-muted font-size-sm font-weight-bolder" v-if="show == 'quantity'">adet</span>
            <span class="text-muted font-size-sm font-weight-bolder" v-if="show == 'pax'">pax</span>
          </div>
        </div>
        <!--end::Info-->
      </div>
      <!--end::Item-->
      <!--begin::Error-->
      <div class="alert alert-primary" role="alert" v-if="error.status">
        {{ error.message }}
      </div>
      <!--end::Error-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: "CategoryBestSellerExperiences",
  props: ["categoryId"],
  data() {
    return {
      show: "quantity",
      byQuantity: [],
      byPax: [],
      error: {
        status: false,
        message: ''
      }
    }
  },
  methods: {
    getBestSellers() {
      ApiService.get(`category/getBestSellerExperiences.req.php?id=${this.categoryId}`)
      .then(({ data }) => {
        this.byQuantity = data.byQuantity;
        this.byPax = data.byPax;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    }
  },
  computed: {
    dataToShow() {
      if (this.show === "quantity") return this.byQuantity;
      if (this.show === "pax") return this.byPax;
      return this.byQuantity;
    }
  },
  mounted() {
    this.getBestSellers();
  }
}
</script>

<style>

</style>