<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Tur ve Aktiviteler
          <span class="d-block text-muted pt-2 font-size-sm">Toplam {{ experiences.length }} adet tur ve aktivite bulunmakta</span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <b-table
        class="table table-head-custom table-head-bg table-borderless table-vertical-center"
        thead-class="d-none"
        show-empty
        empty-html='<div class="alert alert-custom alert-secondary" role="alert"><div class="alert-text">Listelenecek herhangi bir tur ve aktivite bulunamadı!</div></div>'
        stacked="lg"
        :items="experiences"
        :fields="experienceDataTableFields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(experience)="data">
          <div class="py-2 pl-0">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-70 symbol-2by3 flex-shrink-0 mr-4">
                <div class="symbol-label" :style="{ backgroundImage: `url(${data.item.image})` }"></div>
              </div>
              <div>
                <router-link :to="{ name: 'show-experience', params: { id: data.item.id }}" v-slot="{ href }">
                  <a :href="href" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ data.item.name }}</a>
                </router-link>

                <router-link :to="{ name: 'show-destination', params: { id: data.item.destinationId }}" v-slot="{ href }">
                  <a :href="href" class="text-muted text-hover-primary font-weight-bold d-block">{{ data.item.destination }}</a>
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template #cell(rating)="data">
          <div class="d-flex flex-row align-items-center" v-if="data.item.rating > 0">
            <i class="fa fa-star mr-2 text-warning font-size-lg"></i>
            <span class="text-dark-65 font-weight-bolder font-size-h6">{{ data.item.rating }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <span class="label label-lg label-light-success label-inline" v-if="data.item.status == 1">Yayında</span>
          <span class="label label-lg label-light-warning label-inline" v-if="data.item.status == 2">Yayında değil</span>
        </template>
        <template #cell(actions)="data">
          <button @click="remove(data.item.id)" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <router-link
            :to="{ name: 'show-experience', params: { id: data.item.id }}"
            v-slot="{ href }"
          >
            <a :href="href" class="btn btn-icon btn-light btn-hover-primary btn-sm">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"/>
              </span>
            </a>
          </router-link>
        </template>
      </b-table>
      <b-col sm="7" md="6" class="my-1 pl-0" v-if="totalRows > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          size="md"
        >
        </b-pagination>
      </b-col>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";

export default {
  name: "CategoryProducts",
  props: ["categoryId"],
  data() {
    return {
      isBusy: true,
      experiences: [],
      experienceDataTableFields: [
        { key: 'experience', label: 'Etkinlik', sortable: false, class: 'pl-0' },
        { key: 'rating', label: 'Değerlendirme', sortable: false },
        { key: 'status', label: 'Durum', sortable: false },
        { key: 'actions', label: '', sortable: false, class: 'text-right' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5
    }
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getProducts() {
      this.isBusy = true;
      ApiService.get(`category/getProducts.req.php?id=${this.categoryId}`)
      .then(({ data }) => {
        this.experiences = data.products;
        this.totalRows = this.experiences.length;
        this.isBusy = false;
      })
      .catch(({ response }) => {
        this.isBusy = false;
        this.experiences = [];
        this.totalRows = 0;
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },

    remove(id) {
      const self = this;
      Swal.fire({
        title: "Etkinliği kategoriden kaldırmak istediğinize emin misiniz?",
        showCancelButton: true,
        cancelButtonClass: "btn btn-secondary",
        cancelButtonText: "Vazgeç",
        confirmButtonClass: "btn btn-primary",
        confirmButtonText: "Evet, kaldır!",
        heightAuto: false
      })
      .then((result) => {
        if (result.isConfirmed) {
          ApiService.post('category/removeProduct.req.php', { productId: id })
          .then(() => {
            self.getProducts();
          })
          .catch(({ response }) => {
            Swal.fire({
              title: response.data.error,
              text: response.data.errorMessage,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
          })
        }
      })
    }
  }
}
</script>

<style>

</style>