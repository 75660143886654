<template>
  <div class="row">
    <div class="col-xl-8">
      <SubCategoryListings :category-id="categoryId"></SubCategoryListings>
    </div>

    <div class="col-xl-4">
      <CreateSubCategory :category-id="categoryId"></CreateSubCategory>
    </div>
  </div>
</template>

<script>
import SubCategoryListings from "@/view/pages/category/components/sub_category/SubCategoryListings";
import CreateSubCategory from "@/view/pages/category/components/sub_category/CreateSubCategory";

export default {
  name: "SubCategory",
  components: {
    SubCategoryListings,
    CreateSubCategory
  },
  props: ["categoryId"]
}
</script>

<style>

</style>